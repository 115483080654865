import * as React from "react"
import '../style/style.scss'
import {Button, Col, Modal, Row} from "react-bootstrap"
import {useEffect, useState} from "react"
import logo from '../images/icon.png'

const toOrdinal = n => n + ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][n%10]

// markup
const IndexPage = () => {
  const amountOfTickets = 350

  // const [numbers, setNumbers] = useState([...Array(amountOfTickets).keys()])
  const [calledNumbers, setCalledNumbers] = useState([])
  const [showModal, setShowModal] = useState(false)
  // const [inputNumber, setInputNumber] = useState(0)
  const [showReset, setShowReset] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedNumbers, setDeletedNumbers] = useState([])
  const [showVerifyRestoreDeleted, setShowVerifyRestoreDelete] = useState(false)
  const [showVerifyAdd, setShowVerifyAdd] = useState(false)
  const [numberToVerify, setNumberToVerify] = useState(0)
  const [locked, setLocked] = useState(true)
  const [showToggleLock, setShowToggleLock] = useState(false);

  const prizes = {
    1: 50,
    100: 50,
    200: 50,
    300: 50,
    [amountOfTickets-deletedNumbers.length-2]: 250,
    [amountOfTickets-deletedNumbers.length-1]: 500,
    [amountOfTickets-deletedNumbers.length]: 1000
  }

  // This runs when the page is loaded.
  useEffect(() => {
    if(localStorage.getItem('calledTickets')) setCalledNumbers(JSON.parse(localStorage.getItem('calledTickets')))
    if(localStorage.getItem('deletedNumbers')) setDeletedNumbers(JSON.parse(localStorage.getItem('deletedNumbers')))
  }, [])

  useEffect( () => {
    localStorage.setItem('calledTickets', JSON.stringify(calledNumbers))
  }, [calledNumbers])

  useEffect( () => {
    localStorage.setItem('deletedNumbers', JSON.stringify(deletedNumbers))
  }, [deletedNumbers])

  const handleClose = () => {
    document.removeEventListener('keydown', handleClose)
    document.removeEventListener('mousedown', handleClose)

    setShowModal(false)
  }
  const handleShow = () => {
    setShowModal(true)
    document.addEventListener('keydown', handleClose)
    document.addEventListener('mousedown', handleClose)
  }

  const handleReset = () => {setShowReset(true)}
  const doReset = () => {
    setCalledNumbers([])
    setDeletedNumbers([])
    setShowReset(false)
    // setInputNumber(0)
  }

  const removeNumber = (n) => {
    if(calledNumbers.includes(n)) {
      if(locked) return null // do nothing if a number is selected and the block is already selected
      addNumber(n) // if unlocked, add the number back into the pool and mark it as deleted
      setDeletedNumbers([n, ...deletedNumbers])
    } else if (deletedNumbers.includes(n)) {
      if(locked) return null // do nothing if a number is selected and the block is already selected
      setDeletedNumbers(deletedNumbers.filter(d => d !== n)) // remove the number from the deleted numbers
    } else {
      if(locked) handleShow()
      setCalledNumbers([n, ...calledNumbers])
    }
  }

  const addNumber = (n) => {
    const filteredNumbers = calledNumbers.filter(i => i !== n)
    setCalledNumbers(filteredNumbers)
  }

  const restoreLastDeleted = (verify=false) => {
    setDeletedNumbers(deletedNumbers.filter(n => n !== deletedNumbers[0]))
  }

  // const handleDeleteNumber = (n) => {
  //   setDeletedNumbers([n, ...deletedNumbers])
  //   if(locked) {
  //     setShowDeleteModal(true)
  //   }
  // }

  // let cols = numbers.length/5 <= 6 ? 5 : (numbers.length/10 <= 12 ? 10 : (numbers.length/ 15 <= 12 ? 15 : (numbers.length/20 <= 12 ? 20 : 25)))
  // let rows = Math.ceil(numbers.length / cols)
  let cols = 25
  let rows = Math.ceil(amountOfTickets / cols)

  return (
      <div id="App" className={locked ? "App locked" : 'App unlocked'}>
        <header className="App-header">
          { !locked && <><div className="text-center py-2">
            <span style={{cursor: 'pointer'}} role="button" onClick={() => locked ? setShowToggleLock(true) : setLocked(true)}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16"> <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/></svg></span>
            <button className="mx-2" onClick={handleReset}>Reset all</button>
          </div></>}
          <Row>
            { locked && <Col className="my-auto"><h1 style={{verticalAlign:"middle"}}><span role="button" onClick={handleReset}><img src={logo} style={{height:"1em", padding: "0 0.5em", verticalAlign: "middle"}} /><span style={{verticalAlign: "middle"}}>Elimination Draw</span></span></h1></Col> }
            { locked && <Col className="my-auto">{ calledNumbers.length > 0 && <h2><span role="button" onClick={(e) => {e.preventDefault(); setNumberToVerify(calledNumbers[0]); setShowVerifyAdd(true)}}>Last eliminated: {calledNumbers[0]}</span></h2>}</Col>}
            { locked && <Col className="my-auto"><h2><span role="button" onClick={e=>{e.preventDefault(); handleShow()}}>Numbers called: {calledNumbers.length}</span></h2></Col>}
            { locked && <Col className="my-auto"><h2>Numbers left: {amountOfTickets - calledNumbers.length - deletedNumbers.length}</h2></Col>}
          </Row>
          { locked && <div style={{cursor: 'pointer', position: 'absolute', top: 16, right: 16}} role="button" onClick={() => locked ? setShowToggleLock(true) : setLocked(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16"><path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/></svg>
            </div>
          }
        </header>
        <Modal show={showToggleLock} onHide={() => {setShowToggleLock(false)}}>
          <Modal.Header><Modal.Title>Switch to edit mode?</Modal.Title></Modal.Header>
          <Modal.Body>
            <ul>
              <li>In edit mode, click on an item to select is (as drawn).</li>
              <li>Click on a selected item to delete it.</li>
              <li>Click on a deleted item to restore it.</li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowToggleLock(false)}>
              No
            </Button>
            <Button variant="primary" onClick={() => {setLocked(!locked); setShowToggleLock(false)}}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showVerifyRestoreDeleted} onHide={() => {setShowVerifyRestoreDelete(false)}}>
          <Modal.Header><Modal.Title>Restore Deleted?</Modal.Title></Modal.Header>
          <Modal.Body>Do you want to add the number {deletedNumbers[0]} back to the board?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowVerifyRestoreDelete(false)}>
              No
            </Button>
            <Button variant="primary" onClick={() => {restoreLastDeleted(); setShowVerifyRestoreDelete(false)}}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showVerifyAdd} onHide={() => {setShowVerifyAdd(false)}}>
          <Modal.Header><Modal.Title>Mark as not called?</Modal.Title></Modal.Header>
          <Modal.Body>Do you want to mark the number {numberToVerify} as uncalled?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowVerifyAdd(false)}>
              No
            </Button>
            <Button variant="primary" onClick={() => {addNumber(numberToVerify); setShowVerifyAdd(false)}}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDeleteModal} onHide={() => { restoreLastDeleted(); setShowDeleteModal(false)}}>
          <Modal.Header><Modal.Title>Delete?</Modal.Title></Modal.Header>
          <Modal.Body>Do you want to delete the number {deletedNumbers[0]}?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => {restoreLastDeleted(); setShowDeleteModal(false)}}>
              No
            </Button>
            <Button variant="success" onClick={() => setShowDeleteModal(false)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showReset} onHide={() => setShowReset(false)}>
          <Modal.Header><Modal.Title>Reset?</Modal.Title></Modal.Header>
          <Modal.Body>Do you want to reset the numbers?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowReset(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={doReset}>
              Reset
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal} centered dialogClassName={"modal-90w" + (Object.keys(prizes).includes(String(calledNumbers.length)) ? " modal-winner":"")}>
          <button type="button" className="btn-close" onClick={handleClose} aria-label="Close" style={{position: 'absolute', top:'10px', right:'10px'}}></button>
          { !Object.keys(prizes).includes(String(calledNumbers.length)) && <div className="py-2 text-center" style={{fontSize: Object.keys(prizes).includes(String(calledNumbers.length)) ? '25vh' : '50vh'}}>{calledNumbers[0]}</div> }
          { Object.keys(prizes).includes(String(calledNumbers.length)) && <div className="pb-2 text-center" style={{fontSize: '10vh'}}>
            <p className="pt-4 fw-bold">Congratulations!</p>
            <p className="py-2">Ticket <span className="fw-bold">#{calledNumbers[0]}</span> is the {toOrdinal(calledNumbers.length)} ticket drawn!</p>
            <p className="pb-4 fw-bold">You won ${prizes[calledNumbers.length]}!</p>
          </div> }
        </Modal>
        <div className="text-center">
          {[...Array(amountOfTickets).keys()].map(i => <Button key={'item-'+(i+1)} onClick={()=>{removeNumber(i+1)}} role="button" className={`bg-light text-dark opacity-75 d-inline-block rounded-3 text-center py-2 ${calledNumbers.includes(i+1) ? 'dimmed' : ''} ${deletedNumbers.includes(i+1) ? 'deleted' : ''}`} style={{fontSize:`max(12px,min(calc(${100/rows}vh - ${80/rows + 10 + 40}px),calc(${100/cols/2}vw - 10px)))`, lineHeight: 1, padding: 0, border: 'none', width: `max(20px,calc(${100/cols}% - 11px))`, height: `calc(${100/rows}vh - ${80/rows + 10}px)`, margin: '0 10px 10px 0'}}>{i+1}</Button>)}
        </div>
      </div>
  )
}

export default IndexPage
